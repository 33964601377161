import React from 'react';
import Layout from '../../components/common/Layout.jsx';
import HeartDiseaseLayout from '../../components/heart-disease/HeartDiseaseLayout.jsx';

import { connect } from 'react-redux';
import { HEART_DISEASE_NAVIGATION } from '../../state/actionTypes.js';

class Cardiomyopathy extends React.PureComponent {
    constructor(props){
        super(props);
        props.heartDiseaseNavigation(6);
    }

    render(){
        return (
            <Layout>
                <HeartDiseaseLayout>
                    <div className="DilatedCardiomyopathyMainContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionText">Dilated cardiomyopathy (DCM) is a disease of the heart muscle. It is more common in medium to large breeds of dog rather than small dogs, and some breeds are more susceptible to the disease than others. These susceptible breeds include Dobermans, Cocker and Springer Spaniels, Boxers, Irish Setters, German Shepherds, Great Danes, St Bernards and Irish Wolfhounds. Middle-aged male dogs tend to be more commonly affected.</p>
                                <p className="SectionText">In DCM, the heart muscle stretches and becomes abnormally thin, thus enlarging the heart and resulting in a reduced ability to pump. The contractions of the heart are weak and blood is not supplied to the body as efficiently as before the onset of the disease.</p>
                                <p className="SectionText">Because the heart's ability to pump is impaired, circulation is also impaired. For a time your dog's body may make adjustments to allow it to cope. However, at some point, the disease overrides the adjustments that have been made and the dog can become unwell and show signs of heart failure.</p>
                                <p className="SectionText">DCM may affect the dog's body in a number of different ways. See your vet for more information.</p>
                            </div>
                        </div>
                    </div>
                </HeartDiseaseLayout>
            </Layout>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        heartDiseaseNavigation: heartDiseasePage => dispatch({
            type: HEART_DISEASE_NAVIGATION,
            heartDiseasePage,
        })
    }
}

export default connect(null, mapDispatchToProps)(Cardiomyopathy);
